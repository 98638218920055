<template>
  <div @mousemove="onUserInteraction">
    <nav class="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0 shadow">
      <a class="navbar-brand d-none d-lg-block mr-0 px-3" style="width: 170px"><img style="margin-left: 31px"
          alt="IzOnay" :src="imgLogoPath" /></a>
      <!-- <button
        class="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->
      <div class="navbar-nav px-3">
        <select-input :items="accounts" style="width: 300px; max-width:300px; min-width:300px;"
          v-model="currentAccount.Id" @change="accountChange">
          <span><code>{{ currentAccount.Code }}</code>
            {{ currentAccount.Title }}</span>
          <template v-slot:item="{ item }">
            <label v-show="item.WaitingJobCount > 0" class="badge badge-info float-right" style="margin: 4px"
              :title="item.WaitingJobCount + ' bekleyen iş'">{{ item.WaitingJobCount }}</label>
            <code>{{ item.Code }}</code> {{ item.Title }}
          </template>
        </select-input>
      </div>

      <div class="navbar-nav px-3 d-lg-none">
        <select class="form-control" style="margin-left: 12px" @change="navigateToUrl($event)">
          <option value="Dashboard">{{translate('navigation.dashboard')}}</option>
          <option value="DraftDetail">{{translate('navigation.createJob')}}</option>
          <option value="BankOrder">{{translate('navigation.createOrder')}}</option>
          <option value="JobMyList">{{translate('navigation.myJobs')}}</option>
          <option value="JobMyMuhurList">{{translate('navigation.myMuhurs')}}</option>
          <option value="JobList">{{translate('navigation.jobs')}}</option>
        </select>
      </div>

      <div class="navbar-nav flex flex-row px-3 my-1">
        <div class="nav-item align-self-center">
          <select name="" id="" class="custom-select custom-select-sm" @change="langChanged" v-model="language">
            <option value="tr">Türkçe</option>
            <option value="en">English</option>
          </select>
        </div>
        <ul class="list-unstyled align-self-center mb-0">
          <li class="nav-item text-nowrap">
            <div class="user-detail-drop" v-if="showUserDetail" @click="showUserDetail = false"></div>
            <img :src="getProfilePicture" @click="showUserDetail = !showUserDetail" class="profile-picture" />
            <div class="user-detail" v-show="showUserDetail">
              <img src="../assets/ucgen.png" class="float-right" style="margin-top:-6px; margin-right:28px" />
              <div style="padding: 25px 30px 15px 30px" class="text-center">
                <div>
                  <router-link :to="{ name: 'UserProfile', params: {} }"><span @mouseup="showUserDetail = false">{{
                      this.$root.$data.login.User.FirstName
                  }} {{ this.$root.$data.login.User.LastName }}</span>
                  </router-link>
                </div>
                <div v-if="this.$root.$data.login.User.Title">
                  {{ this.$root.$data.login.User.Title.Name }}
                </div>
                <div v-if="this.$root.$data.login.User.Department">
                  {{ this.$root.$data.login.User.Department.Name }}
                </div>
              </div>
              <div style="padding: 11px 30px; border-top: 1px solid #efefef">
                <div style="padding: 7px" class="text-center">
                  <a href="#" @click.prevent="logout">{{translate('login.logout')}}</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div class="menu-container d-none d-lg-block">
          <div class="menu">
            <div id="nav" v-slimscroll="options">
              <ul class="nav flex-column">
                <li>
                  <router-link :to="{ name: 'Dashboard' }" exact>
                    <img src="../assets/menu/home.png" />
                    {{translate('navigation.dashboard')}}
                  </router-link>
                </li>
                <li v-if="hasAuth('Draft')">
                  <router-link :to="{ name: 'DraftDetail', params: { id: emptyObjectId } }">
                    <img src="../assets/menu/drafts.png" />
                    {{translate('navigation.createJob')}}
                  </router-link>
                </li>
                <li v-if="BankOrderPageAuth">
                  <router-link :to="{ name: 'BankOrderDetail', params: { id: emptyObjectId } }">
                    <img src="../assets/menu/bankorder.png" />
                    {{translate('navigation.createOrder')}}
                  </router-link>
                </li>
                
                <li v-if="hasAuth('Approver') || hasAuth('Signer') || hasAuth('Draft')">
                  <router-link :to="{ name: 'JobMyList' }">
                    <img src="../assets/menu/myjobs.png" />
                    {{translate('navigation.myJobs')}}
                  </router-link>
                </li>
                <li v-if="hasAuth('Muhur')">
                  <router-link :to="{ name: 'JobMyMuhurList' }">
                    <img src="../assets/menu/stamp.png" />
                    {{translate('navigation.myMuhurs')}}
                  </router-link>
                </li>
                <li v-if="hasAuth('Report')">
                  <router-link :to="{ name: 'JobList' }">
                    <img src="../assets/menu/jobs.png" />
                    {{translate('navigation.jobs')}}
                  </router-link>
                </li>
                <li v-if="hasAuth('Rem') && hasModule('REM')">
                  <router-link :to="{ name: 'RemList' }">
                    <img src="../assets/menu/sendrem.png" />
                    {{translate('navigation.kep')}}
                  </router-link>
                </li>
                <li>
                  <router-link v-if="hasAuth('Uets') && hasModule('UETS')" :to="{ name: 'UetsList' }">
                    <img src="../assets/menu/uetsIco.png" />
                    {{translate('navigation.uets')}}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'IncomingDocumentList' }">
                    <img src="../assets/menu/gelenevrak.png" />
                    {{translate('navigation.incomingDoc')}}
                  </router-link>
                </li>
                <li v-if="hasAuth('Report')">
                  <router-link :to="{ name: 'Report', params: {} }">
                    <img src="../assets/menu/jobreport.png" />
                    {{translate('navigation.reports')}}
                  </router-link>
                </li>
                <li
                  v-if="hasAuth('CirculationProfile') || hasAuth('Finalizer') || hasAuth('User') || hasAuth('DepartmentManager') || hasAuth('SystemAdmin')">
                  <router-link :to="{ name: 'Summary' }">
                    <img src="../assets/menu/settings.png" />
                    {{translate('navigation.settings')}}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col w-100">
          <div class="alert alert-danger licence-alert" v-if="!currentAccount.IsLicenceActive">
            {{translate('licenceExpiredText1')}}&nbsp;<a class="alert-link"
              :href="'mailto:bilgi@izometri.com.tr?subject=' + translate('licenceRenewal:' + currentAccount.Code)"
              target="_blank">bilgi@izometri.com.tr</a>&nbsp;{{translate('licenceExpiredText2')}} <a class="alert-link"
              href="tel:+902127061736">+90(212) 706 1736</a> {{translate('licenceExpiredText3')}}
          </div>
          <div v-show="$device.type" class="alert alert-warning licence-alert text-center">
            <strong>{{translate('mobileAppAvailable:'+ $device.type)}} </strong> <a :href="$izonay.appLinks[$device.type]"
              class="btn btn-dark btn-sm" target="_blank">{{translate('goToMobileApp')}}</a> <strong>{{translate('or')}}</strong> <a
              href="https://mobil.izonay.com/" class="btn btn-dark btn-sm">{{translate('goToMobileWebsite')}}</a>
          </div>
          <div class="main-content">
            <router-view>Buraya render edilecek</router-view>
          </div>
        </div>
      </div>
    </div>
    <div>
      <span v-if="hideVersionDetails" class="version-info">v{{ version }}</span>
      <router-link v-else class="version-info" :to="{ name: 'Updates', params: {} }">v{{ version }}</router-link>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueSlimScroll from "vue-slimscroll";
import axios from "axios";
import selectInput from "../components/SelectInput.vue";
import i18n from "../i18n"
Vue.use(VueSlimScroll);
export default {
  components: {
    selectInput,
  },
  data() {
    return {
      options: {
        height: "100%",
        size: 0,
      },
      emptyObjectId: window.emptyObjectId,
      showUserDetail: false,
      accounts: [],
      currentAccount: null,
      version: process.env.VUE_APP_VERSION,
      userInteractionCounter: 0,
      userInteractionIntervalId: null,
      refreshTokenIntervalId: null,
      language : localStorage.getItem('language') ? localStorage.getItem('language') : 'tr',
    };
  },
  beforeCreate() {
    document.body.className = "home";
    //window.addEventListener('onmousemove', this.onUserInteraction);

    if (!this.$root.$data.login) {
      var path = window.location.pathname ? window.location.pathname : null;
      this.$router.push({ path: '/', query: { redirect: path } })
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshTokenIntervalId);
    clearTimeout(this.userInteractionIntervalId);
  },
  created() {
    if (!this.$root.$data.login) {
      return;
    } else {
      this.currentAccount = this.$root.$data.login.Account;

      axios.post('/token/refresh')
        .then(x => {
          window.localStorage.setItem('user', JSON.stringify(x.data.Data));
          axios.defaults.headers.common['token'] = x.data.Data.Key;
          this.$root.$data.login = x.data.Data;
        });

      axios.get("/userAccounts/get").then((x) => {
        if (x.data.Success) {
          this.accounts = x.data.Data;
        }
      });

      this.refreshTokenIntervalId = setInterval(() => {
        axios.post('/token/refresh')
          .then(x => {
            window.localStorage.setItem('user', JSON.stringify(x.data.Data));
            axios.defaults.headers.common['token'] = x.data.Data.Key;
            this.$root.$data.login = x.data.Data;
          })
      }, 1000 * 60 * 3);

      this.userInteractionIntervalId = setInterval(() => {
        this.userInteractionCounter += 1;
        if (this.userInteractionCounter >= 20) {
          this.logout();
        }
      }, 1000 * 60);
    }
  },
  computed: {
    imgLogoPath() {
      return window.settings.customization.imgLogo == null ? require("../assets/logo.png") : "../img/" + window.settings.customization.imgLogo;
    },
    hideVersionDetails() {
      if (this.hasAuth("Admin") || this.hasAuth("SystemAdmin"))
        return false;
      else
        return window.settings.customization.hideVersionDetails ? true : false;
    },
    BankOrderPageAuth(){
      var isActive = this.$root?.$data?.login?.Account?.Params?.EnableBankOrderPage;
      return this.hasAuth("Draft") && isActive;
    },
  },
  methods: {
    
    langChanged(e){
      // console.log(e.target.value)
      i18n.locale = e.target.value;
      localStorage.setItem('language', e.target.value); // Store the selected language in local storage
      location.reload()
    },
    onUserInteraction() {
      //console.log('user interaction');
      this.userInteractionCounter = 0;
    },
    navigateToUrl(elm) {

      if (elm.target.value === "DraftDetail") {
        this.$router.push({
          name: elm.target.value,
          params: { id: "000000000000000000000000" },
        });
      } else {
        this.$router.push({ name: elm.target.value });
      }
    },

    accountChange(account) {
      if (account.Code == this.currentAccount.Code) return;

      if (!confirm(this.translate('accountChange:' + account.Title))) return;

      axios
        .post(`/switchaccount/post/${account.Id}`)
        .then((x) => {
          this.errorMessage = null;
          // console.log("Gelen sonuç:", x);
          if (x.data.Success) {
            window.localStorage.setItem("user", JSON.stringify(x.data.Data));
            axios.defaults.headers.common["token"] = x.data.Data.Key;
            this.$root.$data.login = x.data.Data;
            this.currentAccount = this.$root.$data.login.Account;
            var urlArray = this.$route.path.split("/");
            var component = "";
            if(urlArray.length > 2){
              component = "/"+ urlArray[2];
              if(component == "/draft" && urlArray.length == 4)
                component = component + "/"+ this.emptyObjectId;
              
            }
              
            //this.$router.push("/" + x.data.Data.Account.Code);
            this.$router.push({ path: "/" + x.data.Data.Account.Code + component });
            this.$router.go();
          } else {
            alert(x.ErrorMessages);
            this.loading = false;
          }
          console.info("Token geldi: ", axios.defaults.headers.common["token"]);
        })
        .catch((e) => {
          window.processErrorResponse(e, this);
          // console.log("Error:", e);
          // if (e.response === undefined) {
          //   alert(
          //     "Erişim sağlanamadı. Lütfen internet bağlantınızı kontrol edin."
          //   );
          // } else {
          //   alert(
          //     "Beklenmeyen bir hata oluştu. Lütfen bir süre sonra tekrar deneyin"
          //   );
          // }
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.menu-container {
  width: 170px;
}

.menu {
  width: 170px;
  box-shadow: 1px 0px 2px #dddd;
  background: white;
  position: fixed;
  padding-top: 57px;
  top: 0px;
  left: 0;
  bottom: 0;
}

.menu .nav li {
  text-align: center;
}

.menu .nav li a {
  text-align: center;
  background: transparent;
  display: block;
  color: #35495e;
  padding: 12px;
  border-left: 5px solid transparent;
}

.menu .nav li a:hover,
.menu .nav li .active {
  text-decoration: none;
  background-color: #f1f1f1;
  border-left: 5px solid #e94b3d;
}

.menu .nav li a:hover img,
.menu .nav li .active img {
  filter: none;
}

.menu .nav li a img {
  display: block;
  filter: grayscale(100);
  display: block;
  margin: auto;
  max-width: 48px;
  max-height: 48px;
}

.profile-picture {
  width: 42px;
  height: 42px;
  border: 2px solid #dadada;
  border-radius: 21px;
  margin: 8px;
  cursor: pointer;
  z-index: 999999;
}

.user-detail-drop {
  z-index: 999998;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.user-detail {
  width: 300px;
  background: #fff;
  box-shadow: 4px 2px 28px -1px rgba(0, 0, 0, .46);
  display: block;
  right: 8px;
  z-index: 999999;
  position: absolute;
}

.version-info {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 4px 6px;
  font-size: 0.65rem;
  color: #666;
}

.licence-alert {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  margin-left: -15px;
  margin-right: -15px;
}
</style>