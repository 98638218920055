<template>
<div>
    <div v-show="$device.type" class="alert alert-warning alert-dismissible fade show text-center pt-2 pb-2" role="alert">
        <strong>{{translate('mobileAppAvailable:'+ $device.type)}}</strong> <a :href="$izonay.appLinks[$device.type]" class="btn btn-dark btn-sm" target="_blank">{{translate('goToMobileApp')}}</a> <strong>{{translate('or')}}</strong> <a href="https://mobil.izonay.com/" class="btn btn-dark btn-sm">{{translate('goToMobileWebsite')}}</a>
    </div>
    <header id="header">
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <div id="logo-group">
                        <a v-bind:href=imgLogoUrlPath id="logo"> <img v-bind:src=imgLogoPath alt="İzOnay"> </a>
                    </div>
                </div>
                <div class="col-6 text-right">
                    <div v-if="imgSubLogoPath" id="logo-group">
                        <a v-bind:href=imgLogoUrlPath id="logo"> <img v-bind:src=imgSubLogoPath alt="İzOnay"> </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="container" style="max-width:930px;">
        <div class="d-none d-md-block">
            <div class="row">
                <div class="col"></div>
                <div class="col">
                    <ul class="nav nav-pills">
                        <router-link :to="{ path: '/', query: $route.query }" v-slot="{ href, navigate, isExactActive }">
                            <li class="nav-item" role="presentation">
                                <a :href="href" class="nav-link" :class="[isExactActive && 'active']" @click="navigate">{{getLoginEMailFieldText}}</a>
                            </li>
                        </router-link>
                        <router-link v-if="isESignLoginAvailable" :to="{ path: '/esign', query: $route.query }" v-slot="{ href, navigate, isExactActive }">
                            <li class="nav-item" role="presentation">
                                <a :href="href" class="nav-link" :class="[isExactActive && 'active']" @click="navigate">{{translate('login.eSignature')}}</a>
                            </li>
                        </router-link>
                        <router-link v-if="isMobileSignLoginAvailable"  :to="{ path: '/mobilesign', query: $route.query }" v-slot="{ href, navigate, isExactActive }">
                            <li class="nav-item" role="presentation">
                                <a :href="href" class="nav-link" :class="[isExactActive && 'active']" @click="navigate">{{translate('login.mobileSignature')}}</a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 d-none d-md-block" style="overflow:hidden; padding:0;">
                <img v-bind:src=imgGirisPath style="width:100%"/>
            </div>
            <div class="col-md-6" style="background-color: #EFEFEF;">
                <div style="padding:40px 26px;">
                    <router-view></router-view>
                </div>
            </div>
        </div>

        <div v-show="isExternalLoginAvailable" class="row justify-content-center" id="external-logins">
            <div class="col-md-12">   
                <div class="text-center">             
                    <h6>{{translate('login.orLoginWithThis')}}</h6>
                </div>
            </div>
            <div v-if="isADFSLoginEnabled" class="col">
                <LoginWithADFS></LoginWithADFS>
            </div>
            <div v-else class="col">
                <LoginWithMicrosoft ref="microsoftLogin"></LoginWithMicrosoft>
            </div>
        </div>

        <div class="row steps" v-if="!isOnPrem">
            <div class="col-sm-12 col-md-4">
                <h3 style="color:#E94B3D; font-size:19px"><span class="num">1</span>{{translate('login.imzalayiciSteps.downloadImzalayici')}}</h3>
                <p>{{translate('login.imzalayiciSteps.downloadImzalayiciText')}}</p>
                <p>
                    <a href="https://www.izometri.com.tr/imzalayici/imzalayiciSetup.exe" class="btn btn-outline-success">{{translate('login.imzalayiciSteps.download')}}</a>
                </p>
            </div>
            <div class="col-sm-12 col-md-4">
                <h3 style="color:#E94B3D; font-size:19px"><span class="num">2</span>{{translate('login.imzalayiciSteps.plugInESignature')}}</h3>
                <p>{{translate('login.imzalayiciSteps.plugInESignatureText')}}</p>
            </div>
            <div class="col-sm-12 col-md-4">
                <h3 style="color:#E94B3D; font-size:19px"><span class="num">3</span>{{translate('login.imzalayiciSteps.sign')}}</h3>
                <p>{{translate('login.imzalayiciSteps.signText')}}</p>
            </div>
        </div>

    </div>
    <div v-show="!isOnPrem" class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-center text-muted" style="margin:30px;">
                    <small>
                        © {{ new Date().getFullYear() }} izonay.com | {{translate('footer.rights')}}<br>
                        <span v-html="translate('footer.website')"></span>
                    </small>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import LoginWithMicrosoft from '@/components/Login/LoginWithMicrosoft.vue'
import LoginWithADFS from '@/components/Login/LoginWithADFS.vue'


export default {
    beforeCreate(){
        this.$root.$data.login = null;
        document.body.className = '';
    },
    props:{
        redirect:{
            type: String,
            required: false,
            default: null,
        },
    },
    components:{
        LoginWithMicrosoft,
        LoginWithADFS
    },
    computed: {
        isADFSLoginEnabled(){
            return window.settings.authentication?.adfs?.enabled;
        },
        isAzureLoginEnabled(){
            return window.settings.authentication?.adfs?.enableAzure;
        },
        isExternalLoginAvailable()
        {
            if(!this.isOnPrem || this.isADFSLoginEnabled || this.isAzureLoginEnabled)
                return true;
            
            return false;
        },
        isMobileSignLoginAvailable()
        {
            if(this.isOnPrem && window.settings.authentication?.hideMobileSignLogin)
                return false;
            
            return true;
        },
        isESignLoginAvailable()
        {
            if(this.isOnPrem && window.settings.authentication?.hideESignLogin)
                return false;
            
            return true;
        },
        imgLogoPath(){
            return window.settings.customization.imgLogo == null ? require("../assets/logo.png") : "../img/" + window.settings.customization.imgLogo;
        },
        imgSubLogoPath(){
            return window.settings.customization.imgSubLogo == null ? null : "../img/" + window.settings.customization.imgSubLogo;
        },
        imgGirisPath(){
            return window.settings.customization.imgGiris == null ? require("../assets/giris.jpg") : "../img/" + window.settings.customization.imgGiris;
        },
        imgLogoUrlPath(){
            return window.settings.customization.imgLogoURL == null ? "https://www.izonay.com/" : window.settings.customization.imgLogoURL;
        },        
        getLoginEMailFieldText(){
            if(this.isOnPrem)
                return window.settings.authentication?.emailText ?? this.translate('login.email');
            else
                return this.translate('login.email')
        }
    },
    mounted(){
        if(window.settings.azureRedirect && this.isExternalLoginAvailable){
            this.$nextTick(() => {
                this.$refs.microsoftLogin.$refs.microsoftButton.click();
            });
        }
    },
    data: () => ({
        loading: false
    })
}
</script>

<style scoped> 
    #header {
        margin: 0 0 30px 0;
        height: 71px;
        overflow: hidden;
        padding: 0 30px;
        border-width: 0;
        min-height: 28px;
        display: block;
        padding: 0 13px 0 0;
        position: relative;
        z-index: 905;
    }

    #header #logo {
        display: inline-block;
        width: 225px;
        margin-top: 22px;
        margin-left: 10px;
    }

    #logo-group *, .login-info, .login-info *, .minified .menu-item-parent {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    .form-control {
        height: 50px !important;
        border-radius: 0 !important;
    }

    .steps {
        padding-top:30px;
    }

    .steps .num {
        border-radius: 12px;
        background-color: #E94B3D;
        color:#FFF;
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
        position: relative;
        top: -2px;
        margin-right: 6px;;
    }

    #external-logins
    {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
    }
</style>