var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[ 'circles__container', ..._vm.containerClasses ]},_vm._l((_vm.circles),function(circle,index){return _c('div',{key:index,class:[ 'circle__item', ...(circle.hasOwnProperty('classList') ? circle.classList : _vm.circleClasses) ]},[_c('count-down-circle',_vm._b({},'count-down-circle',{
				value: _vm.values[circle.id].value,
				size: 'size' in circle ? circle.size : _vm.size,
				strokeWidth: 'strokeWidth' in circle ? circle.strokeWidth : _vm.strokeWidth,
				strokeColor: 'strokeColor' in circle ? circle.strokeColor : _vm.strokeColor,
				underneathStrokeColor: 'underneathStrokeColor' in circle ? circle.underneathStrokeColor : _vm.underneathStrokeColor,
				fillColor: 'fillColor' in circle ? circle.fillColor : _vm.fillColor,
				valueFontSize: 'valueFontSize' in circle ? circle.valueFontSize : _vm.valueFontSize,
				labelFontSize: 'labelFontSize' in circle ? circle.labelFontSize : _vm.labelFontSize,
				labelPosition: 'labelPosition' in circle ? circle.labelPosition : _vm.labelPosition,
				showValue: 'showValue' in circle ? circle.showValue : _vm.showValue,
				steps: circle.steps,
				label: circle.label
			},false))],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }