import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
 state: {
     user: null,
     approvalUserEditor:
     {
        ListAllusers : false
     },
 },
 getters: {},
 mutations: {
     SET_LIST_ALL_USERS(state, value) {
        state.approvalUserEditor.ListAllusers = value;
     }
 },
 actions: {}
});