<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    beforeCreate(){
        var user = window.localStorage.getItem('user');

        if(user)
        {
          user = JSON.parse(user);
        //   console.log('Oturumu açık kullanıcı: ', user);
          this.$root.$data.login = user;
          axios.defaults.headers.common['token'] = user.Key;
          axios.defaults.headers.common['account'] = this.$route.params.accountCode;
        }

        document.title = window.settings.customization.title == null ? "İzOnay" : window.settings.customization.title;
    },
    data(){
        return { };
    },
    methods:{
        kalinYaz(yazi){
            return "KALIN " + yazi;
        }
    }
}
</script>

<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
