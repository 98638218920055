<template>
    <div class="modal fade show" style="display: block" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ translate(modalTitle) }}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>{{ translate('approvalAuthorityEditor.name') }}</label>
                        <input @input="checkInput('value', $event)" v-model="value" type="text" class="form-control"
                            required />
                    </div>
                </div>
                <div class="modal-footer footer-button-container">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')" data-dismiss="modal">
                        {{ translate('close') }}
                    </button>
                    <button type="button" class="btn btn-primary" :disabled="value==null || value=='' || saveStatus != 'notSaved'" @click="saveRecord">
                        <span v-if="saveStatus == 'notSaved'">{{ translate('save') }}</span>
                        <span v-else-if="saveStatus == 'saving'">{{ translate('saving') }}</span>
                        <span v-else-if="saveStatus == 'saved'">{{ translate('saved') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            value: null,
        };
    },
    props:{
        saveStatus: {
            type: String,
            default: "notSaved"
        },
        modalTitle: {
            type: String,
            default: 'newRecord'
        }
    },
    methods: {
        saveRecord(){
            this.$emit('saveRecord', this.value)
        },
        checkInput(value, event) {
            // Check for invalid characters
            if (/[<>]/.test(event.target.value)) {
                alert(this.translate('errors.invalidSymbols'))
                const editedText = ""
                this.$set(this, value, editedText)
            } else {
                this.$set(this, value, event.target.value)
            }
        },
    },
};
</script>

<style>
.footer-button-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
}
</style>
