import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from "./store";
import dataMixin from './dataMixin.js'
import msalMixin from './msalMixin'
import { BootstrapVue } from 'bootstrap-vue'
import VueAuthImage from './vue-auth-image.js';
import Popover  from 'vue-js-popover'
import VTooltip from 'v-tooltip'
import VPopover from 'vue-js-popover'
import i18n from './i18n'
import 'babel-polyfill';
//import LoadScript from 'vue-plugin-load-script';
import vTitle from 'vuejs-title'
Vue.use(vTitle, {
    maxWidth: "max-content",
    maxHeight: "max-content"
})
Vue.use(Popover)
Vue.use(VTooltip)
Vue.use(VPopover, { tooltip: true })

const izonay = {
    archiveTypes :{
        Delete: { text: i18n.t('incomingChannel.sil')},
        FileSystem: { text: i18n.t('incomingChannel.filePath')  }, 
        Ftp: { text: 'FTP' },
        Sftp: { text: 'sFTP' },
        uygulama: { text: i18n.t('incomingChannel.application')}
    },
    emptyObjectId : '000000000000000000000000',
    documentTypes: {
        BankOrder: { text:  i18n.t('jobDocumentType.bankOrder') },
        Contract: { text:  i18n.t('jobDocumentType.contract') },
        General: { text:  i18n.t('jobDocumentType.general') }
    },
    privacyOptions: {
        Normal: { text: i18n.t('draft.normal'), description: i18n.t('warnings.everyoneCanAccess'), css: 'light' },
        Secret: { text: i18n.t('draftEyp.securityLevels.gzl'), description: i18n.t('warnings.secretJob'), css: 'warning' },
        TopSecret: { text: i18n.t('draftEyp.securityLevels.cgz'), description: i18n.t('warnings.topSecretJob'), css: 'danger' }
    },
    targetSystemKinds: {
        Ftp: { text: 'FTP' },
        Sftp: { text: 'sFTP' },
        Email: { text: i18n.t('login.email') },
        Rem: { text: 'KEP' },
        FileSystem: { text: i18n.t('fileSystem') },
        Api: { text: 'API' },
    },
    docStatus: {
        New: { text: i18n.t('incomingDocument.state.new') },
        Processed: { text: i18n.t('incomingDocument.state.processed') },
        Cancelled: { text: i18n.t('incomingDocument.state.cancel') },
        Processing: { text: i18n.t('incomingDocument.state.processing') },
        NoNeedReply: { text: i18n.t('incomingDocument.state.noNeedReply') },
    },
    docOperation: {
        Assign: { text: i18n.t('incomingDocument.operation.Assign') },
        Revert: { text: i18n.t('incomingDocument.operation.Revert') },
        ChangeCategory: { text: i18n.t('incomingDocument.operation.ChangeCategory') },
        ChangeStatus: { text: i18n.t('incomingDocument.operation.ChangeStatus') },
        Reply: { text: i18n.t('incomingDocument.operation.Reply') },
        Create: { text: i18n.t('incomingDocument.operation.Create') },
    },
    incomingDocType: {
        Kep: { text: 'KEP', css: 'secondary' },
        Uets: { text: 'UETS', css: 'secondary' },
        Eyp: { text: 'EYP', css: 'secondary' },
        Pdf: { text: 'PDF', css: 'secondary' },
        Xml: { text: 'XML', css: 'secondary' },
        Ftp: { text: 'FTP', css: 'secondary' },
        Email: { text: 'Email' , css: 'secondary'},
        Other: { text: i18n.t('other') , css: 'secondary'},
    },
    ozneKinds: {
        KurumKurulus: { text: i18n.t('filePreview.oznePreview.ozneKinds.kurumKurulus') },
        GercekSahis: { text: i18n.t('filePreview.oznePreview.ozneKinds.gercekSahis') },
        TuzelSahis: { text: i18n.t('filePreview.oznePreview.ozneKinds.tuzelSahis') },
    },

    companyKinds: {
        PublicInstitution: { text: i18n.t('filePreview.oznePreview.ozneKinds.kurumKurulus') },
        Person: { text: i18n.t('filePreview.oznePreview.ozneKinds.gercekSahis') },
        Corporation: { text: i18n.t('filePreview.oznePreview.ozneKinds.tuzelSahis') },
    },
    
    ozneTypes: {
        OLUSTURAN: { text: i18n.t('draftEyp.olusturan') },
        DAGITIM: { text: i18n.t('draftEyp.dagitim') }
    },
    jobStatus: {
        Circulating: { text: i18n.t('dashboard.circulating'), css: 'primary' },
        Declined: { text: i18n.t('declined'), css:'danger' },
        Finalizing: { text: i18n.t('dashboard.finalizing'), css:'info' },
        Completed: {text: i18n.t('dashboard.completed'), css:'success' },
        Canceled: {text:i18n.t('finalizerStates.cancelled'), css:'warning'}
    },
    eypSecurityCodes: {
        TSD: { text: i18n.t('draftEyp.securityLevels.tsd') },
        HZO: { text: i18n.t('draftEyp.securityLevels.hzo') },
        OZL: { text: i18n.t('draftEyp.securityLevels.ozl') },
        GZL: { text: i18n.t('draftEyp.securityLevels.gzl') },
        CGZ: { text: i18n.t('draftEyp.securityLevels.cgz') },
        KSO: { text: i18n.t('draftEyp.securityLevels.kso') },
    },
    signatureFormats: {
        CAdES: { text: "CAdES (" + i18n.t('targetSystem.sigStandards.parallel') + ")", symbol:"C", badge:"badge badge-warning" },
        PAdES: { text: "PAdES (PDF)", symbol:"P",badge:"badge badge-info"  },
        XAdES: { text: "XAdES (XML)", symbol:"X",badge:"badge badge-warning"  },
        Guarantee: { text: "XAdES  (" + i18n.t('targetSystem.sigStandards.guarantee') + ")",symbol:"X", badge:"badge badge-warning"  },
        Unsigned: { text: i18n.t('unsigned'), symbol:"N/A", badge:"badge badge-light"  },
        EYP: { text: "EYP v1.3", symbol:"E1",badge:"badge badge-dark"  },
        EYP2: { text: "EYP v2.0", symbol:"E2",badge:"badge badge-dark"  },
        CAdES_Serial: { text: "CAdES (" + i18n.t('targetSystem.sigStandards.serial') + ")", symbol:"CS", badge:"badge badge-warning" }
    },
    remEventCodes: {
        Unknown: { text: i18n.t('jobDocumentType.unknown'), css: 'light' },
        Acceptance: { text: i18n.t('accepted'), css: 'secondary' },
        Rejection: { text: i18n.t('declined'), css: 'danger' },
        Delivery: { text: i18n.t('delivered'), css: 'info' },
        DeliveryExpiration: { text: i18n.t('cantDelivered'), css: 'danger' },
        Download: { text: i18n.t('received'), css: 'info' },
        DownloadExpiration: { text: i18n.t('cantReceived'), css: 'danger' },
        Retrieval: { text: i18n.t('incomingDocFilter.read'), css: 'success' },
        NonRetrievalExpiration : { text: i18n.t('incomingDocFilter.dontRead'), css: 'success' },
    },
    appLinks: {
        IOS: "https://apps.apple.com/us/app/izonay/id1498907784",
        Android: "https://play.google.com/store/apps/details?id=com.izonaymobile"
    },
    serviceProviders:{
        AVEA : { text: "Türk Telekom" },
        TURKCELL : { text: "Turkcell" },
        VODAFONE : { text: "Vodafone" },
    }
};

izonay.install = function(Vue){
    Vue.prototype.$izonay = izonay;
};

Vue.prototype.$device = {
    type: (function() {
        const ua = navigator.userAgent
        if (/android/i.test(ua)) {
            return "Android"
        }
        else if (/iPad|iPhone|iPod/.test(ua)) {
            return "IOS"
        }
    })()
};

window.emptyObjectId = '000000000000000000000000';

window.exportExcel = (function() {
    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
      , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }

    return function(table, name,columns) {
        if (!table.nodeType) table = document.getElementById(table);
        table.removeAttribute("style") 
        table.removeAttribute("href")
        table.removeAttribute("class");
        // console.log(table.innerHTML);
        var rows = table.querySelectorAll('tr');
        var isColumnProgress = (element) => element.Key == 'Progress';
        var columnIndex = columns.findIndex(isColumnProgress)
        if(columnIndex > -1){
            rows.forEach(row => {
                var cells = row.querySelectorAll('td');
                var progressCell = cells[columnIndex + 2];
                var span = progressCell.querySelector('span');
                var innerHTML = span.innerHTML;
                var newInnerHTML = "'" + innerHTML + "'";
                span.innerHTML = newInnerHTML;
            });
        }
        var html =this.replaceData(table.innerHTML);
        // html.re
        var ctx = { worksheet: 'Sheet1',format:'text', table: html };
        var link = document.createElement("a");
        link.download = name+".xls";
        link.href = uri + base64(format(template, ctx));
        link.click();
        link.clear
    //   window.location.href = uri + base64(format(template, ctx))
    };
  })()

window.replaceData =function(table){  
      var data = table
      .replace(/<!---->/g, '')
      .replace(/<!---->/g, '') 
      
      .replace(/<a href="#void">/g, '', '""')
      .replace(/<\/a>/g, '', '""')
      .replace(/<svg>/g, '', '""')
      .replace(/<\/svg>/g, '', '""')

      .replace(/<span>/g, '', '""')
      .replace(/<\/span>/g, '', '""');
      return data;
}

window.getModelStateString = function(modelState){
    var msg = '', i, k;
    for(i in modelState){
        for(k = 0; k < modelState[i].length; k++) {
            msg += modelState[i][k] + '\r\n';
        }
    }
    return msg;
}
window.translate = function(key, category){
    var i18nKey = "";
    var translatedText = ""
    if(key.includes(':')){
        const splittedKeys = key.split(':');
        i18nKey = splittedKeys[0];
    }else{
        i18nKey = key;
    }
    if(category){
        i18nKey = category + '.' + i18nKey;
    }
    try{
        translatedText = i18n.t(i18nKey)
    }catch{
        translatedText = key;
    }
    if(key.includes(':')){
        const splittedKeys = key.split(':');
        for(let i = 1; i < splittedKeys.length; i++){
            translatedText = translatedText.replace('{'+(i-1)+'}',splittedKeys[i]);
        }
    }
    return translatedText
}

window.processErrorResponse = function(x, component){
    if(!x.response) {
        alert(i18n.t('errors.cantConnectError'));
        console.error('Error Response: ', x);
        return;
    }

    var msg = '';

    // var msg = '', i, k;

    // var getModelStateString = function(modelState) {
    //     msg = ''
    //     for(i in modelState){
    //         for(k = 0; k < modelState[i].length; k++) {
    //             msg += modelState[i][k] + '\r\n';
    //         }
    //     }
    //     return msg;
    // }

    switch(x.response.status) {
        case 404:
            alert('Kayıt bulunamadı.');
            break;
        case 400:
            msg = window.getModelStateString(x.response.data.ModelState);

            if(x.response.data.ModelState.InvalidTokenException) {
                msg += "Lütfen yeniden oturum açın.";
                if(component.$root.$data.login) {
                    alert(msg);
                }

                component.$root.$data.login = null;
                window.localStorage.removeItem('user');
                axios.defaults.headers.common.token = null;
                
                router.push('/');
            } else {
                const alertsArray = msg.split('\r\n')
                for (let index = 0; index < alertsArray.length; index++) {
                    const text = alertsArray[index];
                    alertsArray[index] = this.translate(text);
                    alertsArray[index] = alertsArray[index] + '\r\n'

                }
                const alertText = alertsArray.join('');
                alert(alertText);
            }
            break;
        case 401:
            msg = window.getModelStateString(x.response.data.ModelState);
            alert('Yetki sorunu:\r\n' + msg);
            break;
        case 500:
            msg = window.getModelStateString(x.response.data.ModelState);
            alert('Beklemediğimiz bir hata ile karşılaştık.\r\n' + msg);
            break;
        default:
            alert('İşlem tamamlanamadı. Lütfen tekrar deneyin.');
            component.errorMessage = "İşlem gerçekleştirilemedi: " + x;
            break;
    }

    component.loading = false;
    return x;
}
axios.defaults.baseURL = window.settings.apiUrl; // process.env.VUE_APP_API_URL;

import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSimpleAlert from 'vue-simple-alert'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VModal from 'vue-js-modal'
Vue.use(VModal);

Vue.use(BootstrapVue)
Vue.use(VueAuthImage)
Vue.use(VueAxios, axios)
Vue.use(izonay);
Vue.use(VueSimpleAlert);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

Vue.mixin(msalMixin);

Vue.mixin({
    mixins:[dataMixin],

    
    computed: {
        getProfilePicture(){
            return this.$root?.$data?.login?.User?.Base64ProfilePicture == null ? require("./assets/no-profile.jpg") : "data:image/jpg;base64," + this.$root.$data.login.User.Base64ProfilePicture
        },
        getAccountDateLocale(){
            var accountDateLocale = this.$root.$data.login.Account?.Params?.DefaultDateLocale;
            if(accountDateLocale)
                return accountDateLocale;
            else
                return window.settings.defaultLocale ?? "tr";
        },
        isOnPrem(){
            return window.settings.isOnPrem;
        }
    },
    methods: {
        hasAuth(auth){
            var list = this.$root?.$data?.login?.User?.Authorities;
            if(!list)
                return false;

            if(auth != 'Signer' && auth != 'Approved' && auth != 'Muhur' && list.indexOf('Admin') >= 0)
                return true;

            if((auth == 'Finalizer' || auth == 'CirculationProfile' || auth == 'DepartmentManager'  ) 
                && list.indexOf('SystemAdmin') >= 0)
                return true;

            return list.indexOf(auth) >= 0;
        },
        checkAuth(user, auth){
            var list = user.Authorities;
            if(!list)
                return false;

            if(auth != 'Signer' && auth != 'Approved' && auth != 'Muhur' && list.indexOf('Admin') >= 0)
                return true;

            if(auth != 'Signer' && auth != 'Approved' && auth != 'Draft' && auth != 'Muhur'
                && auth != 'Report' && auth != 'Rem' && auth != 'DraftCirculationCustomization' 
                && auth != 'IncomingDocumentsManager' && auth != 'IncomingDocumentsAssigner' && auth != 'Uets' 
                && list.indexOf('SystemAdmin') >= 0)
                return true;

            return list.indexOf(auth) >= 0;
        },
        hasModule(moduleCode) {
            var list = this.$root?.$data?.login?.Account?.Period?.Modules;

            if(!list)
                return false;

            return list.indexOf(moduleCode) >= 0;
        },
        isSupportExternalUsers(){
            return this.$root?.$data?.login?.Account?.IsSupportExternalUsers;
        },
        logout(){
            var externalIdentitySource = this.$root?.$data?.login?.User.ExternalIdentitySource
            
            axios.post('/token/signout', {});
            this.$root.$data.login = null;
            window.localStorage.removeItem('user');
            axios.defaults.headers.common['token'] = null;    
            
            if(externalIdentitySource)
                this.logoutExternalUser(externalIdentitySource);
            else
                this.$router.push('/');       
        },
        logoutExternalUser(externalIdentitySource)
        {
            switch(externalIdentitySource)
            {
                case "Microsoft":
                    this.$msalSignOut();
                    break;
                case "ADFS":
                    this.$msalSignOut();
                    break;
                default:
                    this.$router.push('/');
            }            
        },
        changeState(state, value)
        {
            this.$store.commit(state, value);
        },
        translate(key, category){
            var i18nKey = "";
            var translatedText = ""
            if(key.includes(':')){
                const splittedKeys = key.split(':');
                i18nKey = splittedKeys[0];
            }else{
                i18nKey = key;
            }
            if(category){
                i18nKey = category + '.' + i18nKey;
            }
            try{
                translatedText = i18n.t(i18nKey)
            }catch{
                translatedText = key;
            }
            if(key.includes(':')){
                const splittedKeys = key.split(':');
                for(let i = 1; i < splittedKeys.length; i++){
                    translatedText = translatedText.replace('{'+(i-1)+'}',splittedKeys[i]);
                }
            }
            return translatedText
        }
    }
})

Vue.filter('stripHTML', function (html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
});

// usage: {{ file.size | prettyBytes }}
Vue.filter('prettyBytes', function (num) {
    // jacked from: https://github.com/sindresorhus/pretty-bytes
    if (typeof num !== 'number' || isNaN(num)) {
        return num;
    }

    var exponent;
    var unit;
    var neg = num < 0;
    var units = ['B', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

    if (neg) {
        num = -num;
    }

    if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
    }

    exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1);
    num = (num / Math.pow(1024, exponent)).toFixed(2) * 1;
    unit = units[exponent];

    return (neg ? '-' : '') + num + ' ' + unit;
});

Vue.filter('money', function(num) {
    if (isNaN(num))
        return num;

    return parseFloat(num).toLocaleString();
});

Vue.filter('date', function(date, locale){
    if(!date)
        return null;

    if(!locale)
    {
        var login = window.localStorage.getItem('user');
        if(login)
        {
            login = JSON.parse(login);
            locale = login.Account?.Params?.DefaultDateLocale
        }
        
        if(!locale)
            locale = window.settings.defaultLocale ?? "tr";
    }

    if(typeof date == 'string' && !isNaN(date))
        date = new Date(parseInt(date, 10));

    if(!(date instanceof Date))
        date = new Date(date);

    return date.toLocaleDateString([locale]) ;
});


Vue.filter('datetime', function(date, locale){
    if(!date)
        return null;
        
    if(!locale)
    {
        var login = window.localStorage.getItem('user');
        if(login)
        {
            login = JSON.parse(login);
            locale = login.Account?.Params?.DefaultDateLocale
        }
        
        if(!locale)
            locale = window.settings.defaultLocale ?? "tr";

    }

    if(typeof date == 'string' && !isNaN(date))
        date = new Date(parseInt(date, 10));

    if(!(date instanceof Date))
        date = new Date(date);

    return date.toLocaleDateString([locale]) + ' ' + date.toLocaleTimeString([locale]);
});

Vue.filter('jobStatus', function(state){
    if(!state)
      return '';
    return `<label class="badge badge-${izonay.jobStatus[state].css}">${izonay.jobStatus[state].text}</label>`
})

Vue.filter('privacy', function(state){
    if(!state)
      return '';
    return `<label class="badge badge-${izonay.privacyOptions[state].css}" title="${izonay.privacyOptions[state].description}">${izonay.privacyOptions[state].text}</label>`
})

Vue.filter('privacyDescription', function(state){
    if(!state)
      return '';
    return `${izonay.privacyOptions[state].description}`
})

Vue.filter('remEventCode', function(state){
    if(!state)
      return '';
    return `<label class="badge badge-${izonay.remEventCodes[state].css}">${izonay.remEventCodes[state].text}</label>`
})

Vue.filter('trackingNo', function(trackingNo){
    var revision = parseInt(trackingNo.Revision, 10);
    if(revision && revision > 0)
        revision = '/' + revision;
    else
        revision = '';
    return `${trackingNo.Serial} - ${trackingNo.Number}${revision}`;
});

Vue.filter('checkicon', function(data) {
    if(data === true) {
          return `<svg class="bi bi-check text-success" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
              </svg><span class="d-none">Evet</span>`;
    } else if(data === false) {
        return `<svg class="bi bi-x text-danger" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
          <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
          </svg><span class="d-none">Hayır</span>`;
    }
})
Vue.filter('docStatus', function(status) {
    return izonay.docStatus[status]?.text || status;
})
Vue.filter('docOperation', function(status) {
    return izonay.docOperation[status]?.text || status;
})
Vue.filter('incomingDocType', function(status) {
    if(!status)
      return '';
    return `<label class="badge badge-${izonay.incomingDocType[status]?.css}">${izonay.incomingDocType[status]?.text}</label>`
    // return izonay.incomingDocType[status]?.text || status;
})
Vue.filter('targetSystemKind', function(kind) {
    return izonay.targetSystemKinds[kind]?.text || kind;
})
Vue.filter('serviceProviders', function(kind) {
    return izonay.serviceProviders[kind]?.text || kind;
})
Vue.filter('archiveType', function(kind) {
    return izonay.archiveTypes[kind]?.text || kind;
})

Vue.filter('ozneKind', function(kind) {
    return izonay.ozneKinds[kind]?.text || kind;
})
Vue.filter('companyKind', function(kind) {
    return izonay.companyKinds[kind]?.text || kind;
})
Vue.filter('ozneTipi', function(type) {
    return izonay.ozneTypes[type]?.text || type;
})

Vue.filter('eypSecurityCode', function(code){
    return izonay.eypSecurityCodes[code]?.text || code;
})

Vue.filter('documentType', function(docType) {
    return izonay.documentTypes[docType]?.text || docType;
})

Vue.filter('signatureFormat', function(code){
    return izonay.signatureFormats[code]?.text || code;
})

Vue.filter('signatureFormatSymbol', function(code){
    return izonay.signatureFormats[code]?.symbol || code;
})

Vue.filter('signatureFormatBadge', function(code){
    return izonay.signatureFormats[code]?.badge || code;
})



Array.prototype.sum = function(f) {
    return this.reduce((prev, curr) => {
        var cval = f ? f(curr) : curr;
        var pval = f ? f(prev) : prev;
        if(isNaN(cval))
            cval = 0;

        if(isNaN(pval))
            pval = 0;

        return pval + cval;
    }, 0);
}

Array.prototype.min = function(f) {
    return this.reduce((prev, curr) => {
        var cval = f ? f(curr) : curr;
        var pval = f &&  prev && typeof prev == 'object' ? f(prev) : prev;

        if(isNaN(cval))
            return pval;
        
        if(pval == null || cval < pval)
            return cval;
        return pval;
    }, null);
}

Array.prototype.max = function(f) {
    return this.reduce((prev, curr) => {

        var cval = f ? f(curr) : curr;
        var pval = f ? f(prev) : prev;
        
        if(isNaN(cval))
            return pval;
        
        if(cval > pval)
            return cval;
        return pval;
    }, 0);
}

Date.prototype.diff = function(endingDate) {
    var startingDate = this;
    var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
    var swap = null;
    if (!endingDate) {
        endingDate = new Date().toISOString().substr(0, 10);    // need date in YYYY-MM-DD format
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
        swap = startDate;
        startDate = endDate;
        endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
        if (monthDiff > 0) {
            monthDiff--;
        } else {
            yearDiff--;
            monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
    }

    return {
        isNegative:  swap ? true : false,
        year: yearDiff,
        month: monthDiff,
        day: dayDiff
    }

    //return yearDiff + 'Y ' + monthDiff + 'M ' + dayDiff + 'D';
}