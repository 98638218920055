import axios from 'axios';

const vueAuthImage = {};

function setImgSrc(el, binding) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    var imageUrl = binding.value;
    axios({
      method: 'get',
      url: imageUrl,
      responseType: 'arraybuffer'
    })
    .then(function(resp) {
      var mimeType = resp.headers['content-type'].toLowerCase();
      var imgBase64 = Buffer.from(resp.data, 'binary').toString('base64');
      el.src = 'data:' + mimeType + ';base64,' + imgBase64;
    })
    .catch(function(error) {
      console.error('Error loading image:', error);
      el.src = imageUrl; // Fallback to the original URL
    });
  }
}

vueAuthImage.install = function(Vue) {
  Vue.directive('auth-image', {
    bind: function(el, binding) {
      setImgSrc(el, binding);
    },
    componentUpdated: function(el, binding) {
      setImgSrc(el, binding);
    }
  });
};

export default vueAuthImage;
