import axios from 'axios'

export default {
    methods: {
        async getCirculationProfileSchemas(filter){
            const {data} = await axios.post("circulationprofileschema/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getCirculationProfileSchemasDetailed(filter){
            const {data} = await axios.post("circulationprofileschema/getdetailed", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getExternalSigners(filter){
            const {data} = await axios.post("externalsigner/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getSignGroups(filter){
            const {data} = await axios.post("signgroup/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getUsers(filter){
            const {data} = await axios.post("user/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getDepartments(filter){
            const {data} = await axios.post("department/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getCompany(filter){
            const {data} = await axios.post("company/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getOnlineViewPermission(){
            const {data} = await axios.get("account/GetOnlineViewPermission").catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getFilters(filter){
            const {data} = await axios.post("incomingFilter/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getIncomingChannel(filter){
            const {data} = await axios.post("incomingChannel/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getTitles(filter){
            const {data} = await axios.post("title/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getCategories(filter){
            const {data} = await axios.post("category/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getTargetSystems(){
            const {data} = await axios.post("targetsystem/get", {OrderBy: 'Name', predicate: "IsSingleUse=false && IsActive= true" }).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getOzne(filter){
            const {data} = await axios.post("ozne/get", filter).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getAuthorizationDefinitions(){
            const {data} = await axios.get("user/getAuthorizationDefinitions").catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getRemProviders(){
            const {data} = await axios.post('/remprovider/get').catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getUetsProviders(){
            const {data} = await axios.post('/uetsprovider/get').catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async searchDetsisByName(name){
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Access-Control-Allow-Origin', '*');
            headers.append('GET', 'POST', 'OPTIONS');

            return axios({
                method: 'get',
                url: window.settings.detsisApiUrl.replace(/([/]*)$/g, '') + '/detsis/filterByName',
                params: {name:name},
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers":"*",
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS"
                }
            }).catch(x => {
                window.processErrorResponse(x,this);
            });
        },
        async getDetsisByNumber(number){
            const {data} = await axios.get(window.settings.detsisApiUrl.replace(/([/]*)$/g, '') + '/detsis/get-by-number?detsisNo=' + number).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getDetsisContactInfo(number){
            return axios({
                method: 'get',
                url: window.settings.detsisApiUrl.replace(/([/]*)$/g, '') + '/detsis/getUnitContactInfo',
                params: {detsisNo:number},
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers":"*",
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/json',
                    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS"
                }
            }).catch(x => {
                window.processErrorResponse(x,this);
            });
        },
        async getDetsisUnitRem(number){
            return axios({
                method: 'get',
                url: window.settings.detsisApiUrl.replace(/([/]*)$/g, '') + '/detsis/getUnitRemInfo',
                params: {detsisNo:number},
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers":"*",
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/json',
                    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS"
                }
            }).catch(x => {
                window.processErrorResponse(x,this);
            });
        },        
        async getRemProviderStats(provider){
            const {data} = await axios({
                method: 'get',
                url: '/remprovider/GetRemBoxStats',
                params: { providerId:provider},
            }).catch(x => {
                window.processErrorResponse(x,this);
            });
            return data;
        },
        async getCreateUserVisibility(){
            const {data} = await axios.get("account/GetCreateUserVisibility").catch(x => {
                window.processErrorResponse(x,this)
            })
            return data;
         },
        getMobileSignPromotionVisibility(){
            return window.settings.mobileSignPromotionVisible
        },
        getMobileSignPromotionUrlForMobileSignModal(){
            return window.settings.mobileSignPromotionUrlForMobileSignModal
        },
        getMobileSignPromotionUrlForDashboard(){
            return window.settings.mobileSignPromotionUrlForDashboard
        },
    }
}
