import * as msal from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

const msalConfig = {
  auth: {
    clientId: window.settings.authentication?.adfs?.enabled ? window.settings.authentication?.adfs?.clientId : 'ec73cf0e-8ed3-4099-8eca-ad94ec89a697',
    authority: window.settings.authentication?.adfs?.enabled ? window.settings.authentication?.adfs?.authority : 'https://login.microsoftonline.com/organizations/',
    knownAuthorities: window.settings.authentication?.adfs?.enabled ? window.settings.authentication?.adfs?.knownAuthorities : [],
    validateAuthority: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const loginRequest = {
    scopes: [ "openid", "profile", "User.Read", "email"]
};

var HomeAccountId = null;

export default {
    data(){
        return {
            msalSignInType : null,
            msalHomeAccountId : HomeAccountId,
            msalInstance : msalInstance,
        };
    },
    methods: {  
        msalHandlePopupResponse(resp) {
            if (resp !== null) {
                console.error("Popup not supported");
                throw "Popup not supported";
            }
        },      
        async $msalSignIn(method = "loginRedirect") {
            this.msalSignInType = isIE ? "loginRedirect" : method;
            if (this.msalSignInType === "loginPopup") {
                return msalInstance.loginPopup(loginRequest).then(this.msalHandlePopupResponse).catch(function () {
                    console.error("İşlem sırasında bir hata oluştu.");
                });
            } else if (this.msalSignInType === "loginRedirect") {
                return msalInstance.loginRedirect(loginRequest);
            }
        },        
        $msalSignOut() {
            const logoutRequest = {
                account: msalInstance.getAccountByHomeId(HomeAccountId)
            };
            msalInstance.logoutRedirect(logoutRequest);
        },
    }
}

