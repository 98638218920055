<template>
  <fieldset  :disabled="loading">
  <div class="row">
    <div class="col-md-12">
      <button class="microsoftButton" @click="beginLogin()" id="externalADFSLoginButton">
        <svg class="microsoftLogo"  xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 21 21"><title>MS-SymbolLockup</title><rect x="1" y="1" width="9" height="9" fill="#f25022"/><rect x="1" y="11" width="9" height="9" fill="#00a4ef"/><rect x="11" y="1" width="9" height="9" fill="#7fba00"/><rect x="11" y="11" width="9" height="9" fill="#ffb900"/></svg>
        Sign in with AD FS
      </button>
    </div>
    <div class="col-md-12">
      <div class="text-center">
        <div v-if="errorMessage" class="text-danger">{{translate('login.error')}} {{ errorMessage }}</div>
        <div v-if="infoMessage" class="text-info">{{ infoMessage }}</div>
      </div>
    </div>
  </div></fieldset>
</template>

<script>
import axios from 'axios'

export default {
  name: "ExternalLoginADFS",
  data: () => ({
    loading: false,
    errorMessage: null,
    infoMessage: null,
  }),
  mounted: function () {
    this.msalInstance
      .handleRedirectPromise()
      .then(this.handleResponse)
      .catch((error) => {
        this.errorMessage = this.translate('errors.unexpectedError');
        console.error(error);
      });
  },
  methods: {
    beginLogin()
    {
      var form = this;

      form.loading = true;
      form.errorMessage = "";
      form.infoMessage = this.translate('processingMessages.connectingToServer');
      
      this.$msalSignIn().catch(function (error) {
          console.error(error);

          form.errorMessage = this.translate('errors.serverConnectionFailed'); 
          form.infoMessage = "";
          form.loading = false;
      });
    },
    handleResponse(resp) {
      if (resp != undefined && resp !== null) {
        this.HomeAccountId = resp.account.homeAccountId;
        this.msalInstance.setActiveAccount(resp.account);
        this.Login(resp.idToken, resp.accessToken);
      }
    },
    async LoginWithADFSUser(idToken, accessToken) {
      const { data } = await axios.post("/token/PostExternalADFS", {
        idToken,
        accessToken,
      });
      return data;
    },
    Login(idToken, accessToken) {
      console.info("Oluşturuldu:", this);
      this.loading = true;
      this.errorMessage = null;      
      this.infoMessage = this.translate('processingMessages.loggingIn');

      this.LoginWithADFSUser(idToken, accessToken)
        .then((x) => {
          this.errorMessage = null;
          // console.log("Gelen sonuç:", x);
          if (x.Success) {
            window.localStorage.setItem("user", JSON.stringify(x.Data));
            axios.defaults.headers.common["token"] = x.Data.Key;
            this.$root.$data.login = x.Data;
            
            if(this.$route.query.redirect)
                this.$router.push(this.$route.query.redirect);
            else
                this.$router.push('/' + x.Data.Account.Code);

          } else {
            this.loading = false;
            this.errorMessage = x.ErrorMessages;                      
            this.infoMessage = null;
          }
          console.info("Token geldi: ", axios.defaults.headers.common["token"]);
        })
        .catch((e) => {
          console.error("Error:", e);          
          this.infoMessage = null;

          if (e.response === undefined) {
            this.errorMessage = this.translate('errors.cantConnectError');
          } else {
            this.errorMessage = this.translate('errors.unexpectedError');
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.microsoftLogo {
  padding-right: 12px;
  vertical-align: middle;
}
.microsoftButton {
  color: #5e5e5e;
  background-color: #ffffff;
  font-family: "Segoe UI Regular", "Segoe UI";
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #8c8c8c;
  font-size: 15px;
  font-weight: 600;
  width: "100%";
  min-width: 250px;
  height: "41px";
  margin: 0;
  padding: 8px 12px;
}
.microsoftButton:hover {
  background-color: #edebe9;
}
</style>
