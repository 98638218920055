import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import CircularCountDownTimer from '../components/CircularCountDownTimer.vue';

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    component: Login,
    children: [
      {
        name: 'Login',
        path:'',
        component: () => import('../views/Login/LoginWithEmail.vue')
      },
      {
        path:'/esign',
        component: () => import('../views/Login/LoginWithESignature.vue')
      },
      {
        path:'/mobilesign',
        component: () => import('../views/Login/LoginWithMobileSignature.vue')
      },
      {
        path:'/token',
        component: () => import('../views/Login/LoginWithToken.vue')
      },
      {
        path:'/mfa',
        name:'/mfa',
        component: () => import('../views/Login/LoginWithMFA.vue'),
        props: true
      },
      {
        path:'/forgotpassword',
        component: () => import('../views/Login/ForgotPassword.vue')
      }
      ,
      {
        path:'/passwordreset/:id',
        component: () => import('../views/Login/PasswordReset.vue')
      }
    ]
  },
  {
    path: '/',
    component: CircularCountDownTimer,
    children: [
      {
        name: 'CircularCountDownTimer',
        path: '',
        component: () => import('../views/Login/LoginWithMobileSignature.vue')
      },
      {
        path: '/mobilesign',
        component: () => import('../views/Login/LoginWithMobileSignature.vue')
      }
    ]
  },
  {
    //name: 'Home',
    path: '/:accountCode',
    component: Home,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    children:[
      {
        name: 'Dashboard',
        path: '',
        component: () => import('../views/Dashboard.vue')
      },
      {
        name: 'Updates',
        path: 'updates',
        component: () => import('../views/Updates.vue')
      },
      {
        name: 'UpdatesEN',
        path: 'updates-en',
        component: () => import('../views/UpdatesEN.vue')
      },
      {
        name: 'UserIdentity',
        path: 'useridentity/:id',
        component: () => import('../views/UserIdentity.vue')
      },
      {
        name: 'UserProfile',
        path: 'profile',
        component: () => import('../views/UserProfile.vue')
      },
      {
        name: 'RemManagement',
        path: 'remmanagement',
        component: () => import('../views/RemManagement.vue'),
        children: [
          {
            name: 'RemList',
            path: '',
            component: () => import('../views/RemList.vue'),
            children:[
              {
                name: 'RemDetail',
                path: 'job/:id',
                component: () => import('../views/Job.vue')
              },
            ]
          },
          {
            name: "RemDraft",
            path: ":provider/draft",
            component: () => import('../views/RemDraftList.vue'),
            children: [
              {
                name: "RemNewMessage",
                path: ":id",
                component: () => import('../views/RemNewMessage.vue')
              }
            ]
          },
          {
            name: "RemInbox",
            path: ':provider/inbox',
            component: () => import('../views/RemInboxList.vue'),
            children: [
              {
                name: 'RemInboxDetail',
                path: ':id',
                component: () => import('../views/RemBox.vue')
              }
            ]
          },
          {
            name: "RemOutbox",
            path: ':provider/outbox',
            component: () => import('../views/RemOutboxList.vue'),
            children: [
              {
                name: 'RemOutboxDetail',
                path: ':id',
                component: () => import('../views/RemBox.vue')
              }
            ]
          }
        ]
      },
      {
        name: 'UetsList',
        path: 'uets',
        component: () => import('../views/UetsList.vue'),
        children:[
          {
            name: 'UetsDetail',
            path: ':id',
            component: () => import('../views/Uets.vue')
          },
        ]
      },
      {
        //name: 'Settings',
        path: 'settings',
        component: () => import('../views/Settings.vue'),
        children: [
          {
            name: 'Summary',
            path: '',
            component: () => import('../views/Summary.vue')
          },
          {
            name: 'AccountParams',
            path: 'params',
            component: () => import('../views/AccountParams.vue')
          },
          {
            name: 'TargetSystemList',
            path: 'targetsystem',
            component: () => import('../views/TargetSystemList.vue'),
            children: [
              {
                name: 'TargetSystemDetail',
                path: ':id',
                component: () => import('../views/TargetSystem.vue')
              }
            ]
          },
          {
            name: 'TargetSystemImport',
            path: 'targetsystemimport',
            component: () => import('../views/TargetSystemImport.vue')
          },
          {
            name: 'UserList',
            path: 'user',
            component: () => import('../views/UserList.vue'),
            children: [
              {
                name: 'UserDetail',
                path: ':id',
                component: () => import('../views/User.vue')
              }
            ]
          },
          {
            name: 'UserImport',
            path: 'userimport',
            component: () => import('../views/UserImport.vue')
          },
          {
            name: 'ExternalSignerImport',
            path: 'externalsignerimport',
            component: () => import('../views/ExternalSignerImport.vue')
          },
          {
            name: 'LdapUser',
            path: 'LdapUser',
            component: () => import('../views/LdapUser.vue')
          },
          {
            name: 'RemProviderList',
            path: 'remprovider',
            component: () => import('../views/RemProviderList.vue'),
            children: [
              {
                name: 'RemProviderDetail',
                path: ':id',
                component: () => import('../views/RemProvider.vue')
              }
            ]
          },
          {
            name: 'UetsProviderList',
            path: 'uetsprovider',
            component: () => import('../views/UetsProviderList.vue'),
            children: [
              {
                name: 'UetsProviderDetail',
                path: ':id',
                component: () => import('../views/UetsProvider.vue')
              }
            ]
          },
          {
            name: 'DepartmentList',
            path: 'department',
            component: () => import('../views/DepartmentList.vue'),
            children: [
              {
                name: 'DepartmentDetail',
                path: ':id',
                component: () => import('../views/Department.vue')
              }
            ]
          },
          {
            name: 'TitleList',
            path: 'title',
            component: () => import('../views/TitleList.vue'),
            children: [
              {
                name: 'TitleDetail',
                path: ':id',
                component: () => import('../views/Title.vue')
              }
            ]
          },
          {
            name: 'CategoryList',
            path: 'category',
            component: () => import('../views/CategoryList.vue'),
            children: [
              {
                name: 'CategoryDetail',
                path: ':id',
                component: () => import('../views/Category.vue')
              }
            ]
          },
          {
            name: 'OzneList',
            path: 'ozne',
            component: () => import('../views/OzneList.vue'),
            children: [
              {
                name: 'OzneDetail',
                path: ':id',
                component: () => import('../views/Ozne.vue')
              }
            ]
          },
          {
            name: 'CirculationProfileSchemaList',
            path: 'cp',
            component: () => import('../views/CirculationProfileSchemaList.vue'),
            children: [
              {
                name: 'CirculationProfileSchemaDetail',
                path: ':id',
                component: () => import('../views/CirculationProfileSchema.vue')
              }
            ]
          },
          {
            name: 'IncomingChannelList',
            path: 'incomingChannel',
            component: () => import('../views/IncomingChannelList.vue'),
            children: [
              {
                name: 'IncomingChannelDetail',
                path: ':id',
                component: () => import('../views/IncomingChannel.vue')
              }
            ]
          },
          {
            name: 'ExternalSignerList',
            path: 'externalsigner',
            component: () => import('../views/ExternalSignerList.vue'),
            children: [
              {
                name: 'ExternalSignerDetail',
                path: ':id',
                component: () => import('../views/ExternalSigner.vue')
              }
            ]
          },
          {
            name: 'CompanyList',
            path: 'company',
            component: () => import('../views/CompanyList.vue'),
            children: [
              {
                name: 'CompanyDetail',
                path: ':id',
                component: () => import('../views/Company.vue')
              }
            ]
          },
          {
            name: 'SignGroupList',
            path: 'signgroups',
            component: () => import('../views/SignGroupList.vue'),
            children: [
              {
                name: 'SignGroupDetail',
                path: ':id',
                component: () => import('../views/SignGroup.vue')
              }
            ]
          },
          {
            name: 'SignLimitList',
            path: 'signlimits',
            component: () => import('../views/SignLimitList.vue'),
            children: [
              {
                name: 'SignLimitDetail',
                path: ':id',
                component: () => import('../views/SignLimit.vue')
              }
            ]
          }
        ]
      },
      {
        name: 'DraftList',
        path: 'draft',
        component: () => import('../views/DraftList.vue'),
        children: [
          {
            name: 'DraftDetail',
            path: ':id',
            component: () => import('../views/Draft.vue')
          },
          {
            name: 'DraftWithIncomingDoc',
            path: '000000000000000000000000',
            component: () => import('../views/Draft.vue')
          }
        ]
      },
      {
        name: 'BankOrder',
        path: 'bankorder',
        component: () => import('../views/BankOrderList.vue'),
        children: [
          {
            name: 'BankOrderDetail',
            path: ':id',
            component: () => import('../views/BankOrder.vue')
          },
        //   {
        //     name: 'DraftWithIncomingDoc',
        //     path: '000000000000000000000000',
        //     component: () => import('../views/Draft.vue')
        //   }
        ]
      },
      {
        name: 'JobList',
        path: 'job',
        component: () => import('../views/JobList.vue'),
        children:[
          {
            name: 'JobDetail',
            path: ':id',
            component: () => import('../views/Job.vue')
          },
        ]
      },
      {
        name: 'JobMyList',
        path: 'myjob',
        component: () => import('../views/JobMyList.vue'),
        children:[
          {
            name: 'JobMyDetail',
            path: ':id',
            component: () => import('../views/Job.vue')
          },
          {
            name: 'JobRevise',
            path: 'revise/:id',
            component: () => import('../views/JobRevise.vue')
          },
        ]
      },
      {
        name: 'JobMyMuhurList',
        path: 'mymuhurjob',
        component: () => import('../views/JobMyMuhurList.vue'),
        children:[
          {
            name: 'JobMyMuhurDetail',
            path: ':id',
            component: () => import('../views/Job.vue')
          },
          // {
          //   name: 'JobRevise',
          //   path: 'revise/:id',
          //   component: () => import('../views/JobRevise.vue')
          // },
        ]
      },
      {
        name: 'Report',
        path: 'report',
        component: () => import('../views/Report.vue'),
        // children:[
        //   {
        //     name: 'JobMyDetail',
        //     path: ':id',
        //     component: () => import('../views/Report.vue')
        //   },
        // ]
      },
      {
        name: 'IncomingDocumentList',
        path: 'incomingDoc',
        component: () => import('../views/IncomingDocumentList.vue'),
      },
      {
        name: 'IncomingDocumentDetail',
        path: 'incomingDoc/:id',
        component: () => import('../views/IncomingDocument.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',

  base: process.env.BASE_URL,
  routes
})

export default router
